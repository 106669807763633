<template>
  <div class="flx-row ali-c js-c mt-24" v-show="total>0">
    <el-pagination
      :layout="layoutChild"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      :total="total"
      :current-page="pageNum"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  //"type"值用于区分是主list还是dialog
  props: ["total", "pageNum", "pageSize", "pageSizes", "type", "hideSizes", "layout"],
  data() {
    return {
      num: 1,
      size: 10,
      layoutChild: "total, prev,pager,next,sizes "
    };
  },
  created() {
    //中间变量处理props同步传值
    if(this.layout) this.layoutChild = this.layout
    if(this.hideSizes) this.layoutChild = "total, prev,pager,next"
  },
  methods: {
    //翻页
    handleCurrentChange(page) {
      let pageData = {
        num: page,
        size: this.pageSize
      };
      this.handleTransfer(pageData);
    },
    //改变页显示数
    handleSizeChange(size) {
      let pageData = {
        num: this.pageNum,
        size: size
      };
      this.handleTransfer(pageData);
    },
    //处理子组件向父组件数据同步
    handleTransfer(pageData) {
      this.$emit("syncPageData", pageData, this.type);
      this.$emit("changePage", this.type);
    }
  }
};
</script>