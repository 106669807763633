import request from "@/utils/request";

//获取百货订单核销列表
export function getDepartmentList(data) {
  return request({
    url: '/small-program-merchant/shopOrder/getPhotoList',
    method: 'post',
    data
  });
}

//获取餐饮优惠券订单核销列表
export function getRepastCouponList(data) {
    return request({
      url: '/order-service/order/query/photo',
      method: 'post',
      data
    });
}


//获取优惠券订单核销列表
export function getCouponList(params) {
    return request({
      url: '/coupon-service/couponCustomerRelation/usage/list',
      method: 'get',
      params
    });
}

//接口：分页获取已核销奖品   奖品核销
//地址：http://${BASE_URL}/customer-service/giftCouponVerification/queryGiftVerification
export function getAwardsList(data) {
    return request({
      url: '/customer-service/giftCouponVerification/queryGiftVerification',
      method: 'post',
      data
    });
}

//获取活动订单核销列表
export function getActivityList(data) {
    return request({
      url: '/customer-service/lrActivityVerification/queryActivityCancel',
      method: 'post',
      data
    });
}

//获取所有活动名称列表
export function getAllActivityNameList() {
    return request({
      url: '/customer-service/lrActivityVerification/queryAllActivityName',
      method: 'get',
      params: { name: "" }
    });
}

//  餐饮团购核销列表
export function queryCateringOffset (data) {
  return request({
      url: "/customer-service/lrCateringOffset/queryCateringOffset",
      method: 'post',
      data
  })
}

// 接口：查询门店列表（下拉框）
// 地址：http://${BASE_URL}/goods-service/store/listOfPage2

export function listOfPage2(params) {
  return request({
    url: '/small-inteface/goods-service/store/listOfPage2',
    method: 'get',
    params
  });
}
//获取商户列表（不分页）
//customer-service/lrMerchant/queryMerchantAll
export function queryMerchantAll (data) {
  return request({
      url: "customer-service/lrMerchant/queryMerchantByShopAll",
      method: 'post',
      data
  })
}
// 接口：分页获取优惠券核销
// 地址：http://${BASE_URL}/customer-service/lrCouponVerification/queryCouponVerification
export function queryCouponVerification (data) {
  return request({
      url: "/customer-service/lrCouponVerification/queryCouponVerification",
      method: 'post',
      data
  })
}